import React from "react"
import PatternImg from "../../5_assets/svg/pattern_2.svg"

function BackgroundPattern({ height = "40rem", fadeColor = "offWhite" }) {
   const darkModeClass = fadeColor === "offWhite" ? "dark:from-grey-900 dark:via-grey-900/20 " : ""

   return (
      <div
         className={`h-[${height}] absolute -z-100
                   transform left-1/2 -translate-x-1/2 overflow-y-hidden
                   -top-[15rem] xs:-top-[18rem] md:-top-[10rem] lg:-top-[10rem] xl:-top-[10rem]
                   w-[500%] xxs:w-[500%] xs:w-[250%] sm:w-[170%] md:w-[150%] lg:w-[150%] xl:w-[160%]
                   opacity-100 dark:opacity-50 ${darkModeClass}`}
      >
         <span
            className={`absolute left-0 w-[60%]
                    bg-gradient-to-r
                    from-${fadeColor}
                    first-letter:via-${fadeColor}/40
                    to-transparent ${darkModeClass}`}
         ></span>
         <span
            className={`absolute right-0 w-[60%]
                    bg-gradient-to-l
                    from-${fadeColor}
                    via-${fadeColor}/40
                    to-transparent ${darkModeClass}`}
         ></span>
         <span
            className={`absolute bottom-0 inset-0
                    bg-gradient-to-t
                    from-${fadeColor}
                    via-${fadeColor}/40
                    to-transparent ${darkModeClass}`}
         ></span>
         <PatternImg />
      </div>
   )
}

export default BackgroundPattern
