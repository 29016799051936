import React, { useState, useEffect, useCallback } from "react"
import IconArrow from "../5_assets/svg//icons/arrow-curve-up.svg"

const ScrollToTop = ({ showBelow = 900, label = "" }) => {
   const [show, setShow] = useState(false)

   const handleScroll = useCallback(() => {
      setShow(window.scrollY > showBelow)
   }, [showBelow])

   useEffect(() => {
      if (showBelow) {
         window.addEventListener("scroll", handleScroll, { passive: true })
         return () => window.removeEventListener("scroll", handleScroll)
      }
   }, [showBelow, handleScroll])

   const handleClick = () => {
      window.scrollTo({ top: 0, behavior: "smooth" })
   }

   return (
      <>
         <button
            aria-label="Scroll back to top of page"
            title="Back to top"
            className={`${show ? "animate-fadeIn" : "animate-fadeOut opacity-0 hidden"}
                         inline-block focus:outline-none mx-auto
                         backdrop-blur-sm p-2.5 rounded-full
                         border dark:border-borderDark hover:borderBright
                         bgColorBase text-body__Bright hover:text-body__Bright dark:hover:text-body__Bright shadow-hover
                         transform hover:-translate-y-1 transition ease-in-out duration-500
                         `}
            onClick={handleClick}
         >
            <IconArrow className="w-8 rotate-360" alt="" />
            <span className="sr-only">{label}</span>
         </button>
         {label && <p className="body-sm font-mono space-top-xxs">{label}</p>}
      </>
   )
}

export default ScrollToTop
