import React from "react"
import { Link } from "gatsby"

function Button({
   linkTo,
   href,
   label,
   title,
   iconLeft,
   iconRight,
   size = "sm",
   isDark = false,
   containerCustomClasses,
   textCustomClasses,
   onClick,
   isRounded = true,
}) {
   const containerDefaultClasses = `group/button flex items-center space-x-2 inline-block text-center border2
   ${size === "sm" ? "py-2 px-3 3md:pl-3.5 3md:pr-4" : size === "md" ? "py-2 px-4 3md:pl-6 3md:pr-6 py-3" : ""}
   ${isDark ? "bgColorBase hover:bg-primary" : " hover:bg-primary"}
   ${isRounded ? "border-radius-md" : "border-radius-sm"}
   ${containerCustomClasses}
`

   const iconDefaultClasses = `text-inherit ${
      size === "sm" ? "w-5 md:w-5 text-inherit" : size === "md" ? "w-7 md:w-7 text-inherit" : "w-6 md:w-6 text-inherit"
   }`

   const textDefaultClasses = `font-mono uppercase group-hover/button:underlined group-hover/button:text-body__Bright font-bodybold text-left md:text-nowrap ${
      size === "sm" ? "text-sm" : "md"
   }`

   const ButtonComponent = (
      <button className={containerDefaultClasses} title={label} aria-label={title} role="button" onClick={onClick}>
         {iconLeft && <span className={`${textDefaultClasses} ${iconDefaultClasses}`}>{iconLeft}</span>}
         <p className={`${textDefaultClasses} ${textCustomClasses}`}>{label}</p>
         {iconRight && <span className={`${textDefaultClasses} ${iconDefaultClasses} `}>{iconRight}</span>}
      </button>
   )

   if (linkTo) {
      return <Link to={linkTo}>{ButtonComponent}</Link>
   } else if (href) {
      return (
         <a rel="noopener noreferrer" target="_blank" href={href}>
            {ButtonComponent}
         </a>
      )
   } else if (!href || !linkTo === null) {
      return <div>{ButtonComponent}</div>
   }
}

export default Button
