import React, { useState, useEffect } from "react"
import { useSpring, animated } from "react-spring"
// import ContainerGrid from "../3_layouts/ContainerGrid.js"
// import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ContainerSite from "../3_layouts/ContainerSite"
import Button from "../1_components/Button"
import IconMenu from "../5_assets/svg/icons/menu.svg"
import IconClose from "../5_assets/svg/icons/close.svg"
// import IconEnvelope from "../5_assets/svg/icons/mail-fill.svg"
// import IconEyeClosed from "../5_assets/svg/icons/eye-closed.svg"
// import IconEyeOpen from "../5_assets/svg/icons/eye-open.svg"

// const IconComponent = () => (
//    <div className="block w-12 rounded-full dark:border-borderDark hover:border-primary text-body__Bright text-link ">
//       <span className="block group-hover:hidden">
//          <IconEyeClosed />
//       </span>
//       <span className="hidden group-hover:block">
//          <IconEyeOpen />
//       </span>
//    </div>
// )

const navItems = [
   { to: "/", text: "Home" },
   { to: "/#work", text: "Work" },
   { to: "/#about", text: "About" },
   { to: "/blog", text: "Blog" },
   //    { to: "/about#side-projects", text: "Creative Projects" },
]

const NavItems = ({ navItems }) => (
   <div className="hidden md:flex items-center">
      <ul className="w-full mx-auto flex justify-around items-center">
         {navItems.map((item, i) => (
            <li key={i}>
               <AnchorLink to={item.to} title={item.text}>
                  <p className="body-xs uppercase text-link !text-body__Bright">{item.text}</p>
               </AnchorLink>
            </li>
         ))}
      </ul>
      {/* <Button
         isExternalLink={true}
         href="mailto:iampaolotodde@gmail.com"
         label="Get in touch"
         iconLeft={""}
         containerCustomClasses="text-body__Bright border-2 dark:border-borderDark"
      /> */}
   </div>
)

const MobileNavItems = ({ navItems, onClick }) => (
   <div className="h-[600px] space-top-header bg-surface_bg__Bright space-bottom-md">
      <ul className="space-top-lg px-8">
         {navItems.map((item, i) => (
            <li key={i} onClick={onClick} className="text-left">
               <AnchorLink to={item.to} title={item.text} stripHash>
                  <div className="inline-flex items-start space-x-1.5 ">
                     {/* <span className="text-[2.3rem] mr-2 pt-1">☞</span> */}
                     <p className="header-md uppercase text-body__Grey ">{item.text}</p>
                  </div>
               </AnchorLink>
            </li>
         ))}
         <li className="space-top-xs">
            <Button
               isDark
               size="md"
               isExternalLink={true}
               href="mailto:iampaolotodde@gmail.com"
               label="Get in touch"
               iconLeft={""}
            />
         </li>
      </ul>
   </div>
)

const Navigation = () => {
   const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
   const [scrollPosition, setScrollPosition] = useState(0)

   useEffect(() => {
      const handleScroll = () => setScrollPosition(window.scrollY)
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => window.removeEventListener("scroll", handleScroll)
   }, [])

   const toggleMobileNav = () => setIsMobileNavOpen((prev) => !prev)
   const closeMobileNav = () => setIsMobileNavOpen(false)

   const menuAnimation = useSpring({
      transform: isMobileNavOpen ? "translateX(10%)" : "translateX(100%)",
      opacity: isMobileNavOpen ? 1 : 1,
      config: { mass: 3, tension: 400, friction: 70 },
   })

   const scrollPixels = 85

   return (
      <header className={`fixed z-50 w-full top-0 right-0 left-0`}>
         <ContainerSite>
            <nav
               className={`w-1/4 mx-auto space-top-xs pb-3 rounded-b-2xl overflow-hidden transform duration-300 ease-in-out ${
                  scrollPosition > scrollPixels && "bg-surface_bg__Dark transform -translate-y-2 lg:-translate-y-5"
               }`}
            >
               {/* <Link className="group mr-6" to="/" title="Home">
                     <IconComponent />
                  </Link> */}

               <NavItems navItems={navItems} />
               <button className="md:hidden focus:outline-none" onClick={toggleMobileNav}>
                  {isMobileNavOpen ? (
                     <IconClose className="w-9 h-9 text-body__Bright" />
                  ) : (
                     <IconMenu className="w-9 h-9 text-body__Bright" />
                  )}
               </button>

               <animated.div
                  className="md:hidden z-100 absolute -z-10 top-0 left-0 bottom-0 -right-20"
                  style={menuAnimation}
                  onClick={closeMobileNav}
               >
                  <MobileNavItems navItems={navItems} onClick={closeMobileNav} />
               </animated.div>
            </nav>
         </ContainerSite>
      </header>
   )
}

export default Navigation
